import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/bg-image"

const ContactMePage = () => (
  <Layout>
    <SEO title="Contact me" />

    <div className="row">
      <div className="col contact-col container">
        <h1>get in contact</h1>

        <p>Please contact me using the form below.</p>

        <div className="form-wrapper">
          {/* <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                    <input type="hidden" name="bot-field" /> */}

          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              {/* <label htmlFor="name">Name</label> */}
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
              />
            </div>
            <div className="field half">
              {/* <label htmlFor="email">Email</label> */}
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="field">
              {/* <label htmlFor="message">Message</label> */}
              <textarea
                name="message"
                id="message"
                rows={6}
                placeholder="Message"
                required
              />
            </div>

            <input type="submit" value="Send Message" className="special" />
          </form>
        </div>
      </div>

      <div className="col-image contact-image">
        <StyledBackgroundSection imageName="contact" />
      </div>
    </div>
  </Layout>
)

export default ContactMePage
